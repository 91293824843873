.Filters {
  display: flex;
}
.Filters > * {
  padding: 10px;
}

.actionBar {
  background-color: white;
  box-shadow: inset 0px -1px 0px #f3f3f4;

  padding: 0;
  /* display: flex;
  justify-content: space-between;
  align-items: flex-start; */
}

.displayMobile.actionBar {
  padding: 10px 0;
  box-shadow: none;
}

.actionBar .Filters {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
}

.displayMobile.actionBar .Filters {
  flex-direction: column;
  align-items: stretch;
}

.actionBar .Filters > * {
  width: 100%;
  max-width: 350px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.actionBar.displayMobile .Filters > * {
  max-width: none;
}

.actionBar .Filters .input {
  flex: 1;
}

.Filters .year {
  width: 150px;
}

.displayMobile.actionBar .Filters .year {
  width: 90%;
  align-self: center;
}

.actionBar .Filters .year {
  width: 200px;
}

.actionBar .Filters .year .input {
  padding: 0 10px;
}

.actionBar .Filters .year .label > * {
  margin: 0;
  text-align: center;
}

.actionBar .items .label {
  display: none;
}

/* .actionBar .Filters > .items, .actionBar .Filters > .boundaries {
  max-width: 1000px;
} */
