#map {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.app {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.nav {
  /* background-color: cornflowerblue; */
  background-color: white;
  border-bottom: solid 1px #f3f3f4;

  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav .active {
  font-weight: bold;
}

.nav .hamburg {
  display: none;
}

.nav .side {
  display: flex;
}

.drawernav > div {
  text-align: center;
  margin: 10px 0;
  padding: 0 20px;
}

.drawernav div a {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f4;
  border-radius: 10px;
}

.body {
  /* background-color: blanchedalmond; */
  background-color: white;
  flex: 1;
  position: relative;

  min-height: 1px;
}

.MapPage {
  display: flex;
  align-items: stretch;

  height: 100%;
  margin-bottom: auto;
}

.MapPage > .mapCont {
  width: 100%;
  flex: 1;
}

.MapPage .tab {
  min-width: 500px;
  max-width: 500px;

  background-color: #fbfbfb;

  height: 100%;

  padding: 10px;
  overflow-y: auto;

  margin-bottom: auto;
}

.ModalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 800px;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  max-height: 98dvh;
  overflow-y: auto;
}

.Filters {
  margin: 5px 0;
}

.blockMarker {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  color: #263238;
  display: flex;
  font-size: 8px;
  height: 35px;
  justify-content: center;
  position: relative;
  width: 35px;
  flex-direction: column;
}

.fullHeightTab {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.fullHeightTab .scrollableBody {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.MapPage .header {
  text-decoration: underline;
  font-weight: bold;
  margin-bottom: 5px;
}

.MapPage .MarketCont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 2%;
  padding-bottom: 10px;
}

.MapPage .MarketCont .MarketCard {
  min-width: 48%;
  max-width: 48%;
  align-self: flex-start;
}

.MapPage .blockNode {
  cursor: pointer;
  margin: 0 0 5px 0;
  display: flex;
  padding: 2px 5px;
}

.MapPage .fixedHeader .MuiIconButton-root {
  padding: 5px;
}

.ManageDataTrackers .MuiCheckbox-root {
  padding: 3px;
}

.ManageDataTrackers .MuiListItemIcon-root {
  min-width: 20px;
}

.ManageDataTrackers .MuiListItem-root {
  padding: 0;
}

.ManageDataTrackers .MuiList-root {
  max-height: 500px;
}

.image-item {
  display: flex;
  margin: 10px 0;
}

.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.ModalBox.Full {
  height: 98dvh;
  position: relative;
}

.ModalBox.Full .FullModalInner {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ModalBox.Full .FullModalInner .grower {
  flex: 1;
}

.DataTrackerEntry .DataTrackerEntryResponse .MuiFormControl-root {
  margin: 0;
}

.CreateCustomTracker .form .section {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.CreateCustomTracker .form .section .label {
  width: 100%;
  max-width: 150px;
  text-align: right;
  padding-right: 10px;
}

.CreateCustomTracker .form .section .input {
  flex: 1;
  width: 100%;
}

.filterfab.MuiFab-root {
  display: none;
}

@media only screen and (max-width: 600px) {
  .filterfab.MuiFab-root {
    display: inline-flex;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .MapPage {
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
  }

  .MapPage .mapCont {
    width: 100%;
    flex: 0.25;
  }

  .MapPage .tab {
    flex: 0.75;
    min-width: 0;
    max-width: none;
    width: 100%;
  }

  .nav .hamburg {
    display: block;
    position: relative;
    left: 0;
    position: absolute;
  }

  .nav .side {
    display: none;
  }

  .nav {
    justify-content: center;
    position: relative;
  }

  .pac-target-input {
    display: none;
  }

  .MapPage .MarketCont {
    gap: 2%;
  }

  .MapPage .MarketCont .MarketCard {
    min-width: 98%;
    max-width: 98%;
  }
}
